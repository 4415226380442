import React from 'react'
import Sectiontitle from '../section-title'
import diritto_societario from '../../images/aree/diritto-societario.jpg'
import './style.css'

const Story = () =>{
    return(
        <div id="competenza" className="story-area section-padding">
            <Sectiontitle section={'Diritto Societario'}/>
            <div className="container">
                <div className="story-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                            <p></p>
                                <p></p>
                                <p>Lo Studio fornisce una consulenza strategica in base alle caratteristiche e alle esigenze specifiche della società: <b>operazioni di fusione, scissione, acquisizione d'azienda, o di ramo d'azienda</b> e <b>ristrutturazioni aziendali, procedure di liquidazione</b> volontarie e giudiziali.</p>
                                <p>In sede giudiziale, l'attività viene prestata assistendo sia procedure concorsuali che società, am-ministratori, soci, sindaci e privati in tutte le controversie, quali tra le altre le <b>azioni di responsabilità e le azioni revocatorie e risarcitorie</b></p>
                                <p>Concorrenza sleale e conseguente risarcimento del danno.</p>
                                <p><b>Redazione del Codice Etico sociale.</b></p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={diritto_societario} alt=""/>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

    )
}

export default Story;