import React from  'react';

// components
import SimpleSlider from '../../components/hero';
import Studio from '../../components/studio';
import Header from '../../components/Header';
import MobileMenu from '../../components/mobile-menu';

const Homepage = () => {
    return(
       <div>
           <Header/>
           <MobileMenu/>
           <SimpleSlider/>
           <Studio/>
       </div>
    )
}

export default Homepage;