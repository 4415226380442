import React from 'react'
import Sectiontitle from '../section-title'
import studio1 from '../../images/studio/img-1.jpg'

import './style.css'

const Location = () => {
    return(
        <div id="studio" className="event-section section-padding">
            <Sectiontitle section={'Lo Studio'}/>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="tabs-site-button">
                            <div className="event-tabs">
                                <div className="col-md-12 col-12">
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={studio1} alt=""/>
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-12 col-12">
                                                <div className="event-text">
                                                <p>Lo Studio è stato fondato dall'<b>Avv. Walter Livio Verrengia nel 1991 a Busto Arsizio</b> e si ispira al principio del libero lavoro intellettuale.</p>
                                                <p>Nella struttura operano attualmente, in forma indipendente, Avvocati che negli anni hanno espresso vocazione, dedizione, serietà e competenza nell’affrontare e risolvere, con senso di responsabilità, le più svariate tematiche giuridiche sottoposte alla loro analisi, interpretando tale nobile professione con integrità, senso del dovere e passione, perché in fondo… <b>la questione portata dal Cliente diventa il problema dell’Avvocato!</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Location;