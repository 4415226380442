import React from  'react';
import Sectiontitle from '../section-title'
import bride4 from '../../images/people/4.jpg'


import './style.css'

const People = () => {
    return(
        <section id='people'>
            <div className="groomsmen-bridesmaid-area section-padding">
                <Sectiontitle section={'Elisabetta Magnaghi'}/>
                <div className="container">
                    <div className="groomsmen-bridesmaid-area-menu">
                        <div className="Groomsman-wrap">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 grid">
                                    <div className="groomsmen-bridesmaid-wrap-wl-verrengia">
                                        <div className="groomsmen-bridesmaid-img">
                                            <img src={bride4} alt="bride"/>
                                        </div>
                                        <div className="groomsmen-bridesmaid-content2-wl-verrengia">
                                            <h4>Elisabetta Magnaghi</h4>                
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-12">
                                    <div className="row">
                                        <div className="descrizioneAvvocato">
                                            <p>Laureata in giurisprudenza presso l’<b>Università Cattolica del Sacro Cuore di Milano.</b></p>
<p>Iscritta all’Ordine degli Avvocati di <b>Milano</b> nel 1997.</p>
<p>Iscritta all’Ordine degli Avvocati di <b>Busto Arsizio</b> dal 2016.</p>
<p>Si occupa prevalente di <b>diritto civile</b>, in particolare <b>Diritto bancario; Diritto fallimentare;
Diritto di famiglia; dei minori e delle persone; Diritto immobiliare, condominiale e
delle locazioni, nonché di Recupero crediti; Risarcimento danni; Eredità e
Successioni.</b></p>
<p>Iscritta alle liste del <b>Patrocinio a spese dello Stato.</b></p>
                                        </div>    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    )
}

export default People;