import React from 'react'
import Sectiontitle from '../section-title'
import recupero_crediti from '../../images/aree/recupero-crediti.jpg'
import './style.css'

const Story = () =>{
    return(
        <div id="competenza" className="story-area section-padding">
            <Sectiontitle section={'Recupero Crediti'}/>
            <div className="container">
                <div className="story-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={recupero_crediti} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <p></p>
                                <p>Lo Studio fornisce assistenza giuridica relativa al <b>recupero crediti</b> e nelle <b>domande di ammissione allo stato passivo</b> nonché alle <b>opposizioni allo stato passivo.</b></p>
                                <p>L'attività si svolge, nella fase stragiudiziale, con le <b>indagini patrimoniali</b> per la verifica delle proprietà mobiliari e immobiliari del debitore, le <b>diffide di pagamento</b> e la redazione di un <b>piano di rientro</b>.</p>
                                <p>Nella fase giudiziale, l'attività prosegue con la redazione e deposito di <b>ricorsi per decreto ingiuntivo</b>, la notifica del <b>precetto</b> e dei <b>pignoramenti immobiliari, mobiliari e presso terzi.</b></p>
                                <p>In particolare, lo Studio vanta esperienza pluridecennale nell'ambito delle <b>esecuzioni immobiliari.</b></p>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

    )
}

export default Story;