import React from 'react';
import './style.css'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLinkedin, faGoogle
  } from "@fortawesome/free-brands-svg-icons";
  
import { faCalendarDays, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
    return(
        <div class="social-container">
            <div className="col-lg-5 col-md-12 col-12">
                <div className="event-text-footer">
                    <h5>CONTATTI</h5>
                    <span>Via Arona, 4</span>
                    <span>21052, Busto Arsizio (VA)</span>
                    <span><FontAwesomeIcon icon={faPhone} size="1x" /> 0331-323021  0331-636754</span>
                    <span><FontAwesomeIcon icon={faEnvelope} size="1x" /> info@studiolegaleverrengia.it</span>

                </div>
            </div>
            <a href="https://www.linkedin.com/company/11854171"
            className="social">
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
            <a href="https://goo.gl/maps/4ATtGqGcFtTS5jhb9"
            className="gmaps">
            <FontAwesomeIcon icon={faGoogle} size="2x" />
            </a>
            <a href="https://outlook.office365.com/owa/calendar/Prenotazioniappuntamenti@studiolegaleverrengia.it/bookings/"
            className="schedule">
            <FontAwesomeIcon icon={faCalendarDays} size="2x" />
            </a>
        </div>
    )
}

export default Footer;