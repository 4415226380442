import React from 'react'
import Sectiontitle from '../section-title'
import infortuni_lavoro from '../../images/aree/infortuni-lavoro.jpg'
import './style.css'

const Story = () =>{
    return(
        <div id="competenza" className="story-area section-padding">
            <Sectiontitle section={'Diritto del lavoro - Infortuni sul lavoro'}/>
            <div className="container">
                <div className="story-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <p>Lo Studio ha acquisito una vasta competenza nell'ambito del diritto del lavoro e accompagna il cliente <b>dalla fase dell'assunzione fino alla cessazione del rapporto di lavoro</b>. La consulenza offerta comprende quanto elencato di seguito in maniera non esaustiva:</p>                                <ul>
                                    <li>gestione del personale, aggiornamento e redazione della <b>contrattualistica</b></li>
                                    <li><b>licenziamento individuale e licenziamento collettivo</b></li>
                                    <li><b>procedimenti disciplinari, contestazioni disciplinari e irrogazione delle sanzioni</b></li>
                                    <li>assistenza clienti nell’ambito delle <b>ispezioni</b> degli Enti amministrativi</li>
                                    <li><b>conciliazioni, transazioni, relative a risoluzione del rapporto di lavoro e a composizione bonaria di vertenze di lavoro</b></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={infortuni_lavoro} alt=""/>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

    )
}

export default Story;