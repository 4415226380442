import React from 'react'
import Sectiontitle from '../section-title'
import diritto_immobiliare from '../../images/aree/diritto-immobiliare.jpg'

import './style.css'

const Story = () =>{
    return(
        <div id="competenza" className="story-area section-padding">
            <Sectiontitle section={'Diritto Immobiliare'}/>
            <div className="container">
                <div className="story-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={diritto_immobiliare} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p>Negli anni, lo Studio ha acquisito concreta esperienza nella <b>gestione di patrimoni immobiliari</b>, fornendo continuativamente consulenza ed assistenza, anche giudiziale, in materia di <b>locazione</b>, <b>acquisti e cessioni di immobili</b>, <b>fornitura di servizi</b>, <b>contratti di appalto e mediazione</b>.</p>
                                <p><b>Inadempimento contrattuale</b> e conseguente risarcimento del danno.</p>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

    )
}

export default Story;