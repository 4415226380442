import React from 'react'
import Sectiontitle from '../section-title'
import diritto_tributario from '../../images/aree/diritto-tributario.jpg'
import './style.css'

const Story = () =>{
    return(
        <div id="competenza" className="story-area section-padding">
            <Sectiontitle section={'Diritto Tributario'}/>
            <div className="container">
                <div className="story-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                            <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p>Attività di consulenza ed assistenza, stragiudiziale e giudiziale, con riferimento ad <b>avvisi di accertamento e cartelle di pagamento</b>, emessi dall'Amministrazione Finanziaria: <b>procedimenti giudiziari ai sensi del D.lgs.n.546/1992 e ss.mm. presso ogni grado di giudizio</b> (Commissioni Provinciali, Commissioni Regionali, e Corte di Cassazione).</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={diritto_tributario} alt=""/>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

    )
}

export default Story;