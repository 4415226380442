import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'


class SimpleSlider extends Component {
    render() {
      var settings = {
        
      };
      return (
        <Slider {...settings}>
          <div id="home" className="item1">
              <div className="container">
                  <div className="slide-content">
                      <div className="slide-text">
                          <p>Studio Legale Verrengia</p>
                      </div>
                  </div>
              </div>
          </div>
        </Slider>
      );
    }
  }

export default SimpleSlider;