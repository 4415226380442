import React from 'react'
import Sectiontitle from '../section-title'
import risarcimento_danni from '../../images/aree/risarcimento-danni.jpg'
import './style.css'

const Story = () =>{
    return(
        <div id="competenza" className="story-area section-padding">
            <Sectiontitle section={'Risarcimento Danni'}/>
            <div className="container">
                <div className="story-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <p>Il caso presentato dal Cliente viene analizzato e valutato con l’ausilio di professionisti qualificati del settore (medici legali, tecnici cinematici, professionisti specializzati sulla sicurezza del lavoro). Lo studio fornisce, quindi, consulenza ed assistenza, anche giudiziale, nelle questioni inerenti il <b>risarcimento dei danni</b>, in particolare:</p>
                                <ul>
                                <li> <b>Incidenti stradali.</b> Esperienza acquisita anche per sinistri gravi con conseguenze mortali.</li>
                                <li> <b>Infortunio sul lavoro</b> </li>
                                <li> <b>Responsabilità medica</b> Assistenza per i danni derivanti al paziente (o agli eredi dello stesso) per errori, omissioni o violazione degli obblighi legati alla professione medica o alla struttura sanitaria </li> 
                                <li> <b>Concorrenza sleale</b> </li>
                                <li> <b>Inadempimento contrattuale</b> </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={risarcimento_danni} alt=""/>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

    )
}

export default Story;