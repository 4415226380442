import React from 'react'
import Sectiontitle from '../section-title'
import eredita from '../../images/aree/eredita.jpg'
import './style.css'

const Story = () =>{
    return(
        <div id="competenza" className="story-area section-padding">
            <Sectiontitle section={'Eredità e Successioni'}/>
            <div className="container">
                <div className="story-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={eredita} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <p>Lo studio ha maturato una specifica esperienza in tema di avvicendamento generazionale. Assiste i propri clienti nei seguenti ambiti:</p>
                                <ul>
                                    <li>redazione di <b>patti di famiglia</b></li>
                                    <li>assistenza e consulenza per la redazione di <b>testamenti olografi</b> nel rispetto dei requisiti di legge</li>
                                    <li>assistenza e consulenza per la redazione del <b>testamento biologico e DAT</b> nel rispetto dei requisiti di legge e relativo deposito presso i Comuni di competenza</li>
                                    <li>assistenza e consulenza nella gestione delle <b>controversie di natura successoria</b> nonchè con riferimento a <b>divisione e scioglimento della comunione ereditaria</b></li>
                                    <li><b>assistenza e consulenza del chiamato all'eredità nella fase di apertura della successione</b></li>
                                    <li>redazione di <b>dichiarazioni di successione</b> </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

    )
}

export default Story;