import React from 'react'
import Sectiontitle from '../section-title'
import volontaria_giurisdizione from '../../images/aree/volontaria-giurisdizione.jpg'
import './style.css'

const Story = () =>{
    return(
        <div id="competenza" className="story-area section-padding">
            <Sectiontitle section={'Volontaria Giurisdizione'}/>
            <div className="container">
                <div className="story-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <p>In linea con le norme giuridiche e di deontologia professionale, ma anche con sensibilità morale e nel rispetto delle esigenze del beneficiario, lo Studio si occupa, del diritto e delle azioni di protezione delle persone e dei loro patrimoni, dinanzi al <b>Giudice Tutelare</b>, nei seguenti ambiti: </p>
                                <ul>
                                <li>Assistenza e consulenza nel procedimento di <b>interdizione</b> con la nomina di tutore o <b>inabilitazione</b></li>
                                <li>Assistenza e consulenza nei procedimenti di <b>amministrazioni di sostegno</b>, anche su richiesto dello stesso amministrato</li>
                                <li><b>Assistenza e consulenza al tutore e/o all'amministratore di sostegno, nel compimento degli atti e nell'esercizio delle funzioni attribuite</b></li>
                                <li>Assistenza e consulenza per l'<b>accettazione dell'eredità con beneficio di inventario</b></li>
                                </ul>   
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={volontaria_giurisdizione} alt=""/>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

    )
}

export default Story;