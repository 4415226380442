import React from  'react';

// components
import SimpleSlider from '../../components/hero';
import Aree from '../../components/aree-diritto-tributario';
import Header from '../../components/Header';
import MobileMenu from '../../components/mobile-menu';

const CompetenzeDirittoTributario = () => {
    return(
       <div>
           <Header/>
           <MobileMenu/>
           <SimpleSlider/>
           <Aree/>
       </div>
    )
}

export default CompetenzeDirittoTributario;