import React from 'react'
import Sectiontitle from '../section-title'
import ambiente from '../../images/aree/ambiente.jpg'
import appalti_privati from '../../images/aree/appalti-privati.jpg'
import crisi_aziendale from '../../images/aree/crisi-aziendale.jpg'
import diritto_famiglia from '../../images/aree/diritto-famiglia.jpg'
import diritto_immobiliare from '../../images/aree/diritto-immobiliare.jpg'
import risarcimento_danni from '../../images/aree/risarcimento-danni.jpg'
import materia_bancaria from '../../images/aree/materia-bancaria.jpg'
import volontaria_giurisdizione from '../../images/aree/volontaria-giurisdizione.jpg'
import eredita from '../../images/aree/eredita.jpg'
import infortuni_lavoro from '../../images/aree/infortuni-lavoro.jpg'
import penale from '../../images/aree/penale.jpg'
import diritto_societario from '../../images/aree/diritto-societario.jpg'
import recupero_crediti from '../../images/aree/recupero-crediti.jpg'
import diritto_tributario from '../../images/aree/diritto-tributario.jpg'
import './style.css'

const Story = () =>{
    return(
        <div id="competenza" className="story-area section-padding">
            <Sectiontitle section={'Aree di Competenza'}/>
            <div className="container">
                <div className="story-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={materia_bancaria} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <p></p>
                                <p></p>
                                <p><b>Questioni in materia bancaria</b></p>
                                <p>Mantenendosi in costante aggiornamento sulle novità del settore, lo Studio <b>fornisce assistenza</b> professionale qualificata, stragiudiziale e giudiziale, nelle controversie tra istituti di credito e privati/aziende, in ambito di diritto bancario.</p>
                                <p>Lo Studio è estremamente attivo in procedimenti concernenti <b>commissioni massimo scoperto, commissioni per estinzione anticipata di finanziamenti, anatocismo, usura, contratti derivati</b> e <b>ripetizione dell’indebito</b>, con riferimento a rapporti di conto corrente e mutui, ma anche in punto di <b>validità di fideiussioni bancarie</b> </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <p><b>Risarcimento Danni</b></p>
                                <p>Lo studio ha affrontato e risolto molteplici fattispecie e ha maturato una valida esperienza specifica. </p>
                                <p>Il caso presentato dal Cliente viene analizzato e valutato con l’ausilio di professionisti qualificati del settore (medici legali, tecnici cinematici, professionisti specializzati sulla sicurezza del lavoro) e fornisce consulenza ed assistenza, anche giudiziale, nelle questioni inerenti il <b>risarcimento dei danni</b>, in particolare:</p>
                                <ul>
                                <li> <b>Incidenti stradali</b> </li>
                                <li> <b>Infortunio sul lavoro</b> </li>
                                <li> <b>Responsabilità medica</b> </li> 
                                <li> <b>Concorrenza sleale</b> </li>
                                <li> <b>Inadempimento contrattuale</b> </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={risarcimento_danni} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={diritto_immobiliare} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p><b>Diritto Immobiliare</b></p>
                                <p>Negli anni, lo Studio ha acquisito concreta esperienza nella <b>gestione di patrimoni immobiliari</b>, fornendo continuativamente consulenza ed assistenza, anche giudiziale, in materia di <b>locazione</b>, <b>acquisti e cessioni di immobili</b>, <b>fornitura di servizi</b>, <b>contratti di appalto e mediazione</b>.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                            <p></p>
                                <p></p>
                                <p><b>Appalti privati, Edilizia privata, Risarcimento danni</b></p>
                                <p>Attività di esame della documentazione e consulenza ed assistenza per la redazione di <b>contratti di appalto e relativi disciplinari di incarico di tecnici e professionisti</b>.</p>
                                <p>Lo Studio vanta pluridecennale esperienza nell’ambito dei giudizi aventi ad oggetto le garanzie e la responsabilità tra venditore e acquirente, le garanzie e la responsabilità del progettista, dell’appaltatore e del committente nell’ambito degli <b>appalti privati ai sensi degli artt. 1667 e 1669 cc</b>: dall’esame della fattispecie e l’analisi delle relazioni dei tecnici di parte, al <b>giudizio per accertamento tecnico preventivo</b>, sino all’eventuale successiva <b>causa di merito</b>.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={appalti_privati} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={diritto_famiglia} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p><b>Diritto di Famiglia</b></p>
                                <p>Lo studio vanta una competenza specifica e consolidata nel diritto di famiglia.</p>
                                <p>Redazione, su tutto il territorio nazionale, di <b>dichiarazioni di successione</b> con gestione anche fiscale e tributaria degli aspetti successori</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <p><b>Volontaria Giurisdizione</b></p>
                                <p>In linea con le norme giuridiche e di deontologia professionale, ma anche con sensibilità morale e nel rispetto delle esigenze del beneficiario, lo Studio si occupa, del diritto e delle azioni di protezione delle persone e dei loro patrimoni, dinanzi al <b>Giudice Tutelare</b>, nelle seguenti attività: </p>
                                <ul>
                                <li>Assistenza e consulenza nel procedimento di <b>interdizione</b> con la nomina di tutore o <b>inabilitazione</b></li>
                                <li>Assistenza e consulenza nei procedimenti di <b>amministrazioni di sostegno</b>, anche su richiesto dello stesso amministrato</li>
                                <li><b>Assistenza e consulenza al tutore e/o all'amministratore di sostegno, nel compimento degli atti e nell'esercizio delle funzioni attribuite</b></li>
                                <li>Assistenza e consulenza per l'<b>accettazione dell'eredità con beneficio di inventario</b></li>
                                </ul>   
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={volontaria_giurisdizione} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={crisi_aziendale} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <p><b>Crisi aziendali - Procedure Concorsuali</b></p>
                                <p>Lo studio ha seguito numerose <b>situazioni di crisi aziendali</b>, fornendo consulenza e assistenza legale e, in sinergia con qualificati studi di commercialisti, ha sviluppato una notevole esperienza nel campo delle procedure di <b>liquidazione</b>, volontarie e giudiziali, e del diritto fallimentare e concorsuale: assiste il ceto creditorio e, per la <b>redazione di piani di risanamento, accordi di ristrutturazione, domande di concordato preventivo e fallimentare, domande di fallimento. </b></p>
                                <p>In sede giudiziale, l'attività viene prestata assistendo sia procedure concorsuali che socità, amministratori, soci, sindaci e privati in tutte le controversie, quali tra le altre le <b>azioni di responsabilità e le azioni revocatorie e risarcitorie oltre che nelle domande di ammissione allo stato passivo e nelle opposizioni allo stato passivo e alle procedure una volta dichiarate</b></p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p><b>Ambiente</b></p>
                                <p>Nell'assistenza dell'attività di impresa, lo Studio ha acquisito competenze specifiche anche in questioni di <b>inquinamento acustico</b>, con assistenza giudiziale anche avanti la Suprema Corte di Cassazione.</p>   
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={ambiente} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={eredita} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <p><b>Eredità e Successioni</b></p>
                                <p>Lo studio ha maturato una specifica esperienza in tema di avvicendamento generazionale. Assiste i propri clienti nelle seguenti attività:</p>
                                <ul>
                                    <li>redazione di <b>patti di famiglia</b></li>
                                    <li>assistenza e consulenza per la redazione di <b>testamenti olografi</b> nel rispetto dei requisiti di legge</li>
                                    <li>assistenza e consulenza per la redazione del <b>testamento biologico e DAT</b> nel rispetto dei requisiti di legge e relativo deposito presso i Comuni di competenza</li>
                                    <li>assistenza e consulenza nella gestione delle <b>controversie di natura successoria</b>nonchè con riferimento a <b>divisione e scioglimento della comunione ereditaria</b></li>
                                    <li><b>assistenza e consulenza del chiamato all'eredità nella fase di apertura della successione</b></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p><b>Diritto del lavoro - Infortuni sul lavoro</b></p>
                                <p>Lo Studio ha acquisito una vasta competenza nell'ambito del diritto del lavoro e accompagna il cliente <b>dalla fase dell'assunzione fino alla cessazione del rapporto di lavoro</b>. Vi è una costante cura per la ricerca di soluzioni innovative, "day by day", sempre al passo con le nuove figure delineate dal legislatore</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={infortuni_lavoro} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={penale} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p><b>Penale</b></p>
                                <p>Lo Studio offre consulenza ed assistenza giudiziale in materia di diritto penale nelle seguenti aree di attività:</p>
                                <ul>
                                    <li>reati societari, tributari e fallimentari</li>
                                    <li>infortuni e sicurezza sui luoghi di lavoro</li>
                                    <li>reati in materia ambientale e violazioni edilizie</li>
                                    <li>tutela penale della proprietà industriale ed intellettuale</li>
                                    <li>tutela penale della reputazione sul web</li>
                                    <li><b>responsabilità penale</b> dell'esercente la professione sanitaria</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                            <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p><b>Diritto societario</b></p>
                                <p>Lo Studio fornisce una consulenza strategica in base alle caratteristiche e alle esigenze specifiche della società: <b>operazioni di fusione, scissione, acquisizione d'azienda, o di ramo d'azienda</b> e <b>ristrutturazioni aziendali, procedure di liquidazione</b> volontarie e giudiziali.</p>
                                <p>Ventennale esperienza acquisita in ambito di società legate al <b>settore tessile</b>, realtà ancora oggi molto presente nel territorio lombardo.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={diritto_societario} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={recupero_crediti} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <p></p>
                                <p></p>
                                <p><b>Recupero Crediti</b></p>
                                <p>Lo Studio fornisce assistenza giuridica relativa al <b>recupero crediti</b></p>
                                <p>L'attività si svolge, nella fase stragiudiziale, con le <b>indagini patrimoniali</b> per la verifica delle proprietà mobiliari e immobiliari del debitore, le <b>diffide di pagamento</b> e la redazione di un <b>piano di rientro</b>.</p>
                                <p>Nella fase giudiziale, l'attività prosegue con la redazione e deposito di <b>ricorsi per decreto ingiuntivo</b>, la notifica del <b>precetto</b> e dei <b>pignoramenti immobiliari, mobiliari e presso terzi</b></p>
                                <p>In particolare, lo Studio vanta esperienza pluridecennale nell'ambito delle <b>esecuzioni immobiliari</b></p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                            <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p><b>Diritto tributario</b></p>
                                <p>Attività di consulenza ed assistenza, stragiudiziale e giudiziale, con riferimento ad <b>avvisi di accertamento e cartelle di pagamento</b>, emessi dall'Amministrazione Finanziaria: <b>procedimenti giudiziari ai sensi del D.lgs.n.546/1992 e ss.mm. presso ogni grado di giudizio</b> (Commissioni Provinciali, Commissioni Regionali, e Corte di Cassazione)</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={diritto_tributario} alt=""/>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

    )
}

export default Story;