import React from 'react'
import Sectiontitle from '../section-title'
import ambiente from '../../images/aree/ambiente.jpg'
import './style.css'

const Story = () =>{
    return(
        <div id="competenza" className="story-area section-padding">
            <Sectiontitle section={'Ambiente'}/>
            <div className="container">
                <div className="story-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p>Nell'assistenza dell'attività di impresa, lo Studio ha acquisito competenze specifiche anche in questioni di <b>inquinamento acustico</b>, con assistenza giudiziale anche avanti la Suprema Corte di Cassazione.</p>   
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={ambiente} alt=""/>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

    )
}
export default Story;