import React from 'react';
//import {Link} from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link'
import 'react-sticky-header/styles.css';
import StickyHeader from 'react-sticky-header';

import './style.css'

const Header = () => {
    return(
        <StickyHeader
         header={
          <div className="Header_root">
            <div className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="logo">
                                <h2><Link to='/home'></Link></h2>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="header-menu d-lg-block d-none">
                                <ul className="mobail-menu d-flex">
                                    <li><Link to='/#studio'>Lo Studio</Link></li>
                                    <li><a href='/'>Aree di Competenza</a>
                                        <ul className="submenu">
                                            <li><Link to='/aree-ambiente#competenza'>Ambiente</Link></li>
                                            <li><Link to='/aree-appalti-privati#competenza'>Appalti Privati</Link></li>
                                            <li><Link to='/aree-crisi-aziendale#competenza'>Crisi Aziendale</Link></li>
                                            <li><Link to='/aree-diritto-famiglia#competenza'>Diritto di Famiglia</Link></li>
                                            <li><Link to='/aree-diritto-immobiliare#competenza'>Diritto Immobiliare</Link></li>
                                            <li><Link to='/aree-diritto-societario#competenza'>Diritto Societario</Link></li>
                                            <li><Link to='/aree-diritto-tributario#competenza'>Diritto Tributario</Link></li>
                                            <li><Link to='/aree-eredita#competenza'>Eredità</Link></li>
                                            <li><Link to='/aree-infortuni-lavoro#competenza'>Infortuni Lavoro</Link></li>
                                            <li><Link to='/aree-materia-bancaria#competenza'>Materia Bancaria</Link></li>
                                            <li><Link to='/aree-penale#competenza'>Penale</Link></li>
                                            <li><Link to='/aree-recupero-crediti#competenza'>Recupero Crediti</Link></li>
                                            <li><Link to='/aree-risarcimento-danni#competenza'>Risarcimento Danni</Link></li>
                                            <li><Link to='/aree-volontaria-giurisdizione#competenza'>Volontaria Giurisdizione</Link></li>
                                        </ul>
                                    </li>
                                    <li><a href='/'>Avvocati</a>
                                        <ul className="submenu">
                                            <li><Link to='/avvocato-wl-verrengia#people'>Walter Livio Verrengia</Link></li>
                                            <li><Link to='/avvocato-magnaghi#people'>Elisabetta Magnaghi</Link></li>
                                            <li><Link to='/avvocato-lepore#people'>Chiara Lepore</Link></li>
                                            <li><Link to='/avvocato-pm-verrengia#people'>Paola Maria Verrengia</Link></li>
                                        </ul>
                                    </li>
                                    <li><a href="javascript: document.body.scrollIntoView(false);">Contatti</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
             </div>
          </div>
        }
      >
      </StickyHeader>  
        
    )
}

export default Header;
