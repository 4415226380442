import React from 'react'
import Sectiontitle from '../section-title'
import diritto_famiglia from '../../images/aree/diritto-famiglia.jpg'
import './style.css'

const Story = () =>{
    return(
        <div id="competenza" className="story-area section-padding">
            <Sectiontitle section={'Diritto di Famiglia'}/>
            <div className="container">
                <div className="story-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={diritto_famiglia} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <p>Lo studio vanta una competenza specifica e consolidata nel diritto di famiglia.</p>
                                <p>Prestando attenzione ai diritti dell’individuo e, in particolare, alla tutela dei minori, offre consulenza in materia di diritto di famiglia, nei seguenti ambiti:</p>
                                <ul>
                                    <li><b>unioni civili e convivenze di fatto</b> </li>
                                    <li>ricorsi, congiunti e non, per l’<b>affidamento esclusivo di figli minori</b>, ai sensi dell’art. 337 quater cc.</li>
                                    <li><b>separazione tra coniugi e divorzio</b></li>
                                    <li>problematiche connesse alla <b>tutela del patrimonio nella crisi familiare</b></li>
                                    <li><b>azioni riguardanti lo stato di figlio</b> (disconoscimento di paternità/dichiarazione di paternità/impugnazione del riconoscimento)</li>
                                </ul>                         
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

    )
}

export default Story;