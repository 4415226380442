import React from  'react';
import Sectiontitle from '../section-title'
import bride3 from '../../images/people/3.jpg'


import './style.css'

const People = () => {
    return(
        <section id='people'>
            <div className="groomsmen-bridesmaid-area section-padding">
                <Sectiontitle section={'Paola Maria Verrengia'}/>
                <div className="container">
                    <div className="groomsmen-bridesmaid-area-menu">
                        <div className="Groomsman-wrap">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 grid">
                                    <div className="groomsmen-bridesmaid-wrap-wl-verrengia">
                                        <div className="groomsmen-bridesmaid-img">
                                            <img src={bride3} alt="bride"/>
                                        </div>
                                        <div className="groomsmen-bridesmaid-content2-wl-verrengia">
                                            <h4>Paola Maria Verrengia</h4>                
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-12">
                                    <div className="row">
                                        <div className="descrizioneAvvocato">
                                            <p>Laureata in giurisprudenza presso l’<b>Università degli Studi dell’Insubria di Varese.</b></p>
<p><b>Diploma di specializzazione per le professioni legali</b> presso l’Università Cattolica del
Sacro Cuore di Milano (master 2° livello). <b>Tirocinio presso il Tribunale di Milano,
Sezione Lavoro</b>, con il Giudice Dott. Scarzella.</p>
<p>Iscrizione all’Ordine degli Avvocati di <b>Busto Arsizio</b> dal 2016.</p>
<p>Si occupa prevalentemente di diritto civile, in particolare di <b>Diritto bancario; Eredità e
Successioni; Diritto del lavoro; Diritto di famiglia; Diritto immobiliare, condominiale
e delle locazioni; Recupero crediti e Risarcimento danni.</b></p>
                                        </div>    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    )
}

export default People;