import React from 'react'
import Sectiontitle from '../section-title'
import appalti_privati from '../../images/aree/appalti-privati.jpg'

import './style.css'

const Story = () =>{
    return(
        <div id="competenza" className="story-area section-padding">
            <Sectiontitle section={'Appalti Privati, Edilizia privata, Risarcimento danni'}/>
            <div className="container">
                <div className="story-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                            <p></p>
                                <p></p>
                                <p>Attività di esame della documentazione e consulenza ed assistenza per la redazione di <b>contratti di appalto e relativi disciplinari di incarico di tecnici e professionisti</b>.</p>
                                <p>Lo Studio vanta pluridecennale esperienza nell’ambito dei giudizi aventi ad oggetto le garanzie e la responsabilità tra venditore e acquirente, le garanzie e la responsabilità del progettista, dell’appaltatore e del committente nell’ambito degli <b>appalti privati ai sensi degli artt. 1667 e 1669 cc</b>: dall’esame della fattispecie e l’analisi delle relazioni dei tecnici di parte, al <b>giudizio per accertamento tecnico preventivo</b>, sino all’eventuale successiva <b>causa di merito</b>.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={appalti_privati} alt=""/>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

    )
}

export default Story;