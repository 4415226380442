import React from  'react';
import Sectiontitle from '../section-title'
import bride1 from '../../images/people/1.jpg'


import './style.css'

const People = () => {
    return(
        <section id='people'>
            <div className="groomsmen-bridesmaid-area section-padding">
                <Sectiontitle section={'Walter Livio Verrengia'}/>
                <div className="container">
                    <div className="groomsmen-bridesmaid-area-menu">
                        <div className="Groomsman-wrap">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 grid">
                                    <div className="groomsmen-bridesmaid-wrap-wl-verrengia">
                                        <div className="groomsmen-bridesmaid-img">
                                            <img src={bride1} alt="bride"/>
                                        </div>
                                        <div className="groomsmen-bridesmaid-content2-wl-verrengia">
                                            <h4>Walter Livio Verrengia</h4>                
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-12">
                                    <div className="row">
                                        <div className="descrizioneAvvocato">
                                            <p>Laureato in giurisprudenza presso l’<b>Università degli Studi “Federico II” di Napoli.</b></p>
<p><b>Fonda lo Studio Legale Verrengia nel 1991 a Busto Arsizio.</b></p>
<p>Iscritto all’Ordine degli Avvocati di <b>Busto Arsizio</b> dal 1991.</p>
<p>Iscritto all’Albo <b>Avvocati Cassazionisti</b> dal 2004.</p>
<p>Esperto in particolare di diritto civile, si occupa di: <b>Diritto bancario; Diritto immobiliare,
condominiale e delle locazioni; Risarcimento danni; Appalti ed edilizia privata;
Diritto di famiglia; Diritto ambientale; Eredità e Successioni; Volontaria
giurisdizione; Procedure concorsuali; Diritto societario; Recupero crediti; Diritto
tributario; Diritto del lavoro-infortuni sul lavoro; Diritto Penale</b>.
Iscritto alle liste del <b>Patrocinio a spese dello Stato</b>.</p>
                                        </div>    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    )
}

export default People;