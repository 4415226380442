import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Homepage from '../HomePage/index'
import Competenze from '../Competenze/index'
import CompetenzeAmbiente from '../Competenze-ambiente/index'
import CompetenzeAppaltiPrivati from '../Competenze-appalti-privati/index'
import CompetenzeCrisiAziendale from '../Competenze-crisi-aziendale/index'
import CompetenzeDirittoFamiglia from '../Competenze-diritto-famiglia/index'
import CompetenzeDirittoImmobiliare from '../Competenze-diritto-immobiliare/index'
import CompetenzeDirittoSocietario from '../Competenze-diritto-societario/index'
import CompetenzeDirittoTributario from '../Competenze-diritto-tributario/index'
import CompetenzeEredita from '../Competenze-eredita/index'
import CompetenzeInfortuniLavoro from '../Competenze-infortuni-lavoro/index'
import CompetenzeMateriaBancaria from '../Competenze-materia-bancaria/index'
import CompetenzePenale from '../Competenze-penale/index'
import CompetenzeRecuperoCrediti from '../Competenze-recupero-crediti/index'
import CompetenzeRisarcimentoDanni from '../Competenze-risarcimento-danni/index'
import CompetenzeVolontariaGiurisdizione from '../Competenze-volontaria-giurisdizione/index'
import Avvocati from '../Avvocati/index'
import AvvocatiLepore from '../Avvocati-lepore/index'
import AvvocatiMagnaghi from '../Avvocati-magnaghi/index'
import AvvocatiPMVerrengia from '../Avvocati-pm-verrengia/index'
import AvvocatiWLVerrengia from '../Avvocati-wl-verrengia/index'
import Scrollbar from '../../components/scrollbar'
import Footer from '../../components/footer'
import './App.css';


const App = () => { 

  return (
    <div className="App">
       <Router>
          <Switch>
            <Route exact path='/' component={Homepage} />
            <Route exact path='/aree' component={Competenze} />
            <Route exact path='/aree-ambiente' component={CompetenzeAmbiente} />
            <Route exact path='/aree-appalti-privati' component={CompetenzeAppaltiPrivati} />
            <Route exact path='/aree-crisi-aziendale' component={CompetenzeCrisiAziendale} />
            <Route exact path='/aree-diritto-famiglia' component={CompetenzeDirittoFamiglia} />
            <Route exact path='/aree-diritto-immobiliare' component={CompetenzeDirittoImmobiliare} />
            <Route exact path='/aree-diritto-societario' component={CompetenzeDirittoSocietario} />
            <Route exact path='/aree-diritto-tributario' component={CompetenzeDirittoTributario} />
            <Route exact path='/aree-eredita' component={CompetenzeEredita} />
            <Route exact path='/aree-infortuni-lavoro' component={CompetenzeInfortuniLavoro} />
            <Route exact path='/aree-materia-bancaria' component={CompetenzeMateriaBancaria} />
            <Route exact path='/aree-penale' component={CompetenzePenale} />
            <Route exact path='/aree-recupero-crediti' component={CompetenzeRecuperoCrediti} />
            <Route exact path='/aree-risarcimento-danni' component={CompetenzeRisarcimentoDanni} />
            <Route exact path='/aree-volontaria-giurisdizione' component={CompetenzeVolontariaGiurisdizione} />
            <Route exact path='/avvocati' component={Avvocati} />
            <Route exact path='/avvocato-wl-verrengia' component={AvvocatiWLVerrengia} />
            <Route exact path='/avvocato-magnaghi' component={AvvocatiMagnaghi} />
            <Route exact path='/avvocato-lepore' component={AvvocatiLepore} />
            <Route exact path='/avvocato-pm-verrengia' component={AvvocatiPMVerrengia} />
          </Switch>
          <Footer/>
          <Scrollbar/>
      </Router>
      
    </div>
  );
}

export default App;
