import React, { Component } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Collapse, CardBody, Card } from 'reactstrap';
//import {Link} from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link'
import './style.css';

export default class MobileMenu extends Component {

    state = {
        isMenuShow: false,
        isOpen: false,
    }

    menuHandler = () => {
        this.setState({
            isMenuShow: !this.state.isMenuShow
        })
    }

    setIsOpen = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }


    render() {

        const { isMenuShow, isOpen } = this.state;

        return (
            <div>
                <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
                    {/* <div className="clox" onClick={this.menuHandler}>Close Me</div> */}
                        <div className="logo2">
                            <h2><Link to='/'>Studio Legale Verrengia</Link></h2>
                        </div>
                        <ul className="responsivemenu">
                            <li><p onClick={this.setIsOpen}>Aree di competenza<i className="fa fa-angle-right" aria-hidden="true"></i></p></li>
                            <Collapse isOpen={isOpen}>
                                <Card>
                                    <CardBody>
                                    <li><Link to='/aree-ambiente#competenza'>Ambiente</Link></li>
                                    <li><Link to='/aree-appalti-privati#competenza'>Appalti Privati</Link></li>
                                    <li><Link to='/aree-crisi-aziendale#competenza'>Crisi Aziendale</Link></li>
                                    <li><Link to='/aree-diritto-famiglia#competenza'>Diritto di Famiglia</Link></li>
                                    <li><Link to='/aree-diritto-immobiliare#competenza'>Diritto Immobiliare</Link></li>
                                    <li><Link to='/aree-diritto-societario#competenza'>Diritto Societario</Link></li>
                                    <li><Link to='/aree-diritto-tributario#competenza'>Diritto Tributario</Link></li>
                                    <li><Link to='/aree-eredita#competenza'>Eredità</Link></li>
                                    <li><Link to='/aree-infortuni-lavoro#competenza'>Infortuni Lavoro</Link></li>
                                    <li><Link to='/aree-materia-bancaria#competenza'>Materia Bancaria</Link></li>
                                    <li><Link to='/aree-penale#competenza'>Penale</Link></li>
                                    <li><Link to='/aree-recupero-crediti#competenza'>Recupero Crediti</Link></li>
                                    <li><Link to='/aree-risarcimento-danni#competenza'>Risarcimento Danni</Link></li>
                                    <li><Link to='/aree-volontaria-giurisdizione#competenza'>Volontaria Giurisdizione</Link></li>
                                    </CardBody>
                                </Card>
                            </Collapse>
                        </ul>
                        <ul className="responsivemenu">
                        <li><p onClick={this.setIsOpen}>Avvocati<i className="fa fa-angle-right" aria-hidden="true"></i></p></li>
                            <Collapse isOpen={isOpen}>
                                <Card>
                                    <CardBody>
                                        <li><Link to='/avvocato-wl-verrengia#people'>Walter Livio Verrengia</Link></li>
                                        <li><Link to='/avvocato-magnaghi#people'>Elisabetta Magnaghi</Link></li>
                                        <li><Link to='/avvocato-lepore#people'>Chiara Lepore</Link></li>
                                        <li><Link to='/avvocato-pm-verrengia#people'>Paola Maria Verrengia</Link></li>
                                    </CardBody>
                                </Card>
                            </Collapse>
                        </ul>
                </div>
                <div className="showmenu" onClick={this.menuHandler}><i className="fa fa-bars" aria-hidden="true"></i></div>         
            </div>
        )
    }
}