import React from 'react'
import Sectiontitle from '../section-title'
import crisi_aziendale from '../../images/aree/crisi-aziendale.jpg'
import './style.css'

const Story = () =>{
    return(
        <div id="competenza" className="story-area section-padding">
            <Sectiontitle section={'Crisi aziendali - Procedure Concorsuali'}/>
            <div className="container">
                <div className="story-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={crisi_aziendale} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <p></p>
                                <p></p>
                                <p></p>
                                <p>Lo studio ha seguito numerose <b>situazioni di crisi aziendali</b>, fornendo consulenza e assistenza legale e, in sinergia con qualificati studi di commercialisti, ha sviluppato una notevole esperienza nel campo delle procedure di <b>liquidazione</b>, volontarie e giudiziali, e del diritto fallimentare e concorsuale: assiste il ceto creditorio e, per la <b>redazione di piani di risanamento, accordi di ristrutturazione, domande di concordato preventivo e fallimentare, domande di fallimento. </b></p>
                                <p><b>Procedimenti in materia di composizione delle crisi da sovraindebitamento.</b></p>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

    )
}

export default Story;