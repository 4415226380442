import React from 'react'
import Sectiontitle from '../section-title'
import materia_bancaria from '../../images/aree/materia-bancaria.jpg'
import './style.css'

const Story = () =>{
    return(
        <div id="competenza" className="story-area section-padding">
            <Sectiontitle section={'Questioni in materia bancaria'}/>
            <div className="container">
                <div className="story-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={materia_bancaria} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <p></p>
                                <p></p>
                                <p>Mantenendosi in costante aggiornamento sulle novità del settore, lo Studio <b>fornisce assistenza</b> professionale qualificata, stragiudiziale e giudiziale, nelle controversie tra istituti di credito e privati/aziende, in ambito di diritto bancario.</p>
                                <p>Lo Studio è estremamente attivo in procedimenti concernenti <b>commissioni massimo scoperto, commissioni per estinzione anticipata di finanziamenti, anatocismo, usura, contratti derivati</b> e <b>ripetizione dell’indebito</b>, con riferimento a rapporti di conto corrente e mutui, ma anche in punto di <b>validità di fideiussioni bancarie.</b> </p>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

    )
}

export default Story;