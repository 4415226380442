import React from  'react';
import Sectiontitle from '../section-title'
import bride2 from '../../images/people/2.jpg'

import './style.css'

const People = () => {
    return(
        <section id='people'>
            <div className="groomsmen-bridesmaid-area section-padding">
                <Sectiontitle section={'Chiara Lepore'}/>
                <div className="container">
                    <div className="groomsmen-bridesmaid-area-menu">
                        <div className="Groomsman-wrap">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 grid">
                                    <div className="groomsmen-bridesmaid-wrap-wl-verrengia">
                                        <div className="groomsmen-bridesmaid-img">
                                            <img src={bride2} alt="bride"/>
                                        </div>
                                        <div className="groomsmen-bridesmaid-content2-wl-verrengia">
                                            <h4>Chiara Lepore</h4>                
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-12">
                                    <div className="row">
                                        <div className="descrizioneAvvocato">
                                            <p></p>
                                            <p></p>
                                            <p></p>
                                            <p>Laureata in Giurisprudenza presso l’<b>Università Statale di Milano</b>.</p>
<p>Iscritta all’Ordine degli Avvocati di <b>Busto Arsizio</b> dal 2008.</p>
<p>Si occupa prevalentemente di diritto civile, in particolare di <b>Diritto bancario; Diritto
immobiliare, condominiale e delle locazioni; Appalti privati – Edilizia privata –
Risarcimento danni; Diritto di famiglia; Eredità e Successioni; Recupero crediti;
Diritto Tributario; Diritto del Lavoro</b>.</p>
                                        </div>    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    )
}

export default People;