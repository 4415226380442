import React from  'react';
import Sectiontitle from '../section-title'
import bride1 from '../../images/people/1.jpg'
import bride2 from '../../images/people/2.jpg'
import bride3 from '../../images/people/3.jpg'
import bride4 from '../../images/people/4.jpg'


import './style.css'

const People = () => {
    return(
        <section id='people'>
            <div className="groomsmen-bridesmaid-area section-padding">
                <Sectiontitle section={'Gli Avvocati'}/>
                <div className="container">
                    <div className="groomsmen-bridesmaid-area-menu">
                        <div className="Groomsman-wrap">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 grid">
                                    <div className="groomsmen-bridesmaid-wrap">
                                        <div className="groomsmen-bridesmaid-img">
                                            <img src={bride1} alt="bride"/>
                                        </div>
                                        <div className="groomsmen-bridesmaid-content2">
                                            <h4>Walter Livio Verrengia</h4>
                                            <p>Laureato in giurisprudenza presso l’Università degli Studi “Federico II” di Napoli.</p>
                                            <p>Fonda lo Studio Legale Verrengia nel 1991 a Busto Arsizio.</p>                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 grid">
                                    <div className="groomsmen-bridesmaid-wrap">
                                        <div className="groomsmen-bridesmaid-img">
                                            <img src={bride4} alt="bride"/>
                                        </div>
                                        <div className="groomsmen-bridesmaid-content2">
                                            <h4>Elisabetta Magnaghi</h4>
                                            <p>Laureata in giurisprudenza presso l’Università Cattolica del Sacro Cuore di Milano.</p>
                                            <p>Iscritta all’Ordine degli Avvocati di Busto Arsizio.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 grid">
                                    <div className="groomsmen-bridesmaid-wrap">
                                        <div className="groomsmen-bridesmaid-img">
                                            <img src={bride2} alt="bride"/>
                                        </div>
                                        <div className="groomsmen-bridesmaid-content2">
                                            <h4>Chiara Lepore</h4>
                                            <p>Laureata in Giurisprudenza presso l’Università Statale di Milano.</p>
                                            <p>Iscritta all’Ordine degli Avvocati di Busto Arsizio (2008).</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 grid">
                                    <div className="groomsmen-bridesmaid-wrap">
                                        <div className="groomsmen-bridesmaid-img">
                                            <img src={bride3} alt="bride"/>
                                        </div>
                                        <div className="groomsmen-bridesmaid-content2">
                                            <h4>Paola Maria Verrengia</h4>
                                            <p>Laureata in giurisprudenza presso l’Università degli Studi dell’Insubria di Varese.</p>
                                            <p>Iscrizione all’Ordine degli Avvocati di Busto Arsizio dal 2016.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    )
}

export default People;